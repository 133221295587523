<script lang='ts'>
  // about:config -> media.navigator.permission.disabled -> true - for testing in localhost
  import { onMount } from 'svelte'

  import { API_URL, getCookie } from '@/helpers/apiCall'
  import { notifier } from '@/helpers/notifier'

  export let src: string
  export let url = ''
  export let params: any = {}
  export let onAfterSuccess = (data: any) => {}
  export let changeState = (state: string) => {}
  export let reload = false

  let blob: any
  let mediaRecorder: any
  let audioPlayer: any
  let uploaded = false
  let recordingState = ''

  onMount(() => {
    audioRecorderInit()
  })

  const audioRecorderInit = () => {
    if (navigator.mediaDevices) {
      const constraints = { audio: true }
      let chunks: any[] = []

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          mediaRecorder = new MediaRecorder(stream)

          mediaRecorder.onstop = () => {
            blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
            chunks = []
            audioPlayer.src = URL.createObjectURL(blob)
          }

          mediaRecorder.ondataavailable = (e: any) => {
            chunks.push(e.data)
          }
        })
        .catch((err) => {
          console.error('The following error occurred: ' + err)
        })
    } else {
      const err = 'Media recorder not supported!'
      notifier.warning(err)
      throw err
    }
  }

  const fileUpload = (reqData: any) => {
    for (const i in params) {
      reqData.append(i, params[i])
    }
    reqData.append('src', src)
    reqData.append('file_upload', true)
    fetch(API_URL + url, {
      body: reqData,
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + getCookie('jwt')
      },
      method: 'POST'
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          notifier.warning(data.error)
        } else {
          uploaded = true
          recordingState = ''
          onAfterSuccess(data)
          if (reload) {
            setTimeout(() => {
              uploaded = false
            }, 2000)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleClickOnStart = () => {
    recordingState = 'in-progress'
    changeState('in-progress')
    mediaRecorder.start()
  }

  const handleClickOnStop = () => {
    recordingState = 'stopped'
    changeState('stopped')
    mediaRecorder.stop()
  }

  const handleClickOnDelete = () => {
    recordingState = ''
    changeState('')
    uploaded = false
  }

  const handleClickOnUpload = () => {
    const reader = new FileReader()

    reader.onload = (event: any) => {
      const fd = new FormData()
      fd.append('audio', event.target.result)
      fileUpload(fd)
    }

    reader.readAsDataURL(blob)
  }
</script>

<div>

  {#if !uploaded}
    <div class='audio-recorder {recordingState}'>
      {#if recordingState === 'stopped'}
        <div class='audio-block'>
          <audio bind:this={audioPlayer} class='player' controls src="" />
          <button class='default-button deleteButton' type='button' on:click={handleClickOnDelete}>Delete</button>
          <button class='default-button upload-button' type='button' on:click={handleClickOnUpload}>Upload</button>
        </div>
      {:else}
        Record audio 🎙️ - click on icon to start recording
        <button class='start' type='button' on:click={handleClickOnStart}>Start recording</button>
        <button class='stop' type='button' on:click={handleClickOnStop}>Stop recording...</button>
      {/if}
    </div>
  {:else}Uploaded{/if}
</div>

<style lang='scss'>
  .audio-recorder {
    > .start {
      display: inline-block;
      color: var(--white-text);
      background: var(--green-4);
    }

    > .stop {
      display: none;
      color: var(--white-text);
      background: var(--red-3);
    }
  }

  .audio-block {
    display: flex;
    align-items: center;

    > .player {
      margin: 1rem;
    }

    > .deleteButton {
      color: var(--white-text);
      background: var(--red-3);
    }
  }

  .in-progress {
    > .start {
      display: none;
    }

    > .stop {
      display: inline-block;
    }
  }

  /* stylelint-disable-next-line rscss/class-format */
  .stopped {
    > .start,
    > .stop {
      display: none;
    }
  }
</style>
