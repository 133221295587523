<script lang='ts'>
  // create code for uploading json file to the server

  import Svelecte from 'svelecte'
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { flattenObject } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  document.title = 'Import translations'

  let allLanguages: any = []
  import('@/config/allLanguages').then((data) => { allLanguages = data.allLanguages })

  let files: FileList
  let uploadForm: HTMLFormElement
  let jsonFile: any = ''
  let language: number = 0

  function logFile (event: any) {
    return event.target.result
  }
  const uploadJson = () => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      const tmp = logFile(e)
      const fileName = files[0].name
      const extension = fileName?.split('.')?.pop()?.toLowerCase()
      const langNameFromFile = fileName.split('.')[0]
      const currentLanguage = allLanguages.find((lang: any) => lang.id === language)
      if (langNameFromFile !== currentLanguage.iso) {
        alert(`Filename must be ${currentLanguage.iso}.json or ${currentLanguage.iso}.csv for ${currentLanguage.name} language`)
        return
      }
      if (extension === 'json') {
        jsonFile = JSON.stringify(flattenObject(JSON.parse(tmp || '{}')), null, 2)
      } else {
        jsonFile = tmp
      }
      await fetchData('mod/i18nImport', { data: jsonFile, extension, language })
      uploadForm.reset()
      notifier.success('Translations imported')
      language = 0
    }
    reader.readAsText(files[0])
  }
</script>
<div class='page-wrapper'>
  <p><a href='/admin/translations-edit' use:link>Edit</a></p>
  <h1>Import translations</h1>
  <form bind:this={uploadForm} on:submit|preventDefault={uploadJson}>
    <p><label for='file'>Upload file</label>
      <input id='file' accept='.json,.csv' type='file' bind:files /><br />
      <!-- eslint-disable max-len -->
      <small>Choose a file with json or csv extension. If file has csv extensions columsn are: id, translation, description, original translation (English or other language). Just id and translation are required when importing, but we sending all 4 to people who translating.</small>
    </p>
    <p>
      <label for='language'>Select language</label>
      {#if allLanguages.length > 0}
        <span class='svelecte-wrapper'><Svelecte
          inputId='language'
          options={allLanguages}
          placeholder='Select language'
          bind:value={language}
        /></span>
      {/if}
    </p>
    <button type='submit'>Upload</button>
  </form>
  <p class='important-notice'>Please check if language you choose is correct. You can destroy current translation if you choose wrong language.</p>
</div>

<style>
  .page-wrapper {
    max-width: 64rem;
    margin: 0 auto;
  }

  small {
    display: block;
    line-height: 1.4;
  }

  .svelecte-wrapper {
    display: block;
    max-width: 30rem;
  }

  .important-notice {
    color: var(--red-3);
  }
</style>
