<script lang='ts'>
  import Svelecte from 'svelecte'
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { getFormData } from '@/helpers/formHelpers'
  import { toInt, unflattenObject } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  document.title = 'Edit translations'

  let allLanguages: any[] = []
  import('@/config/allLanguages').then((data) => { allLanguages = data.allLanguages })

  let languages: any[] = []
  let translations: any = {}
  let languagesData: any[] = []
  let formData: any = {}
  let form: HTMLFormElement
  let jsons: any = {}
  let sent = false

  function load () {
    const tmpLangs = languages
    fetchData('mod/i18nLoad', { languages: tmpLangs }).then((data) => {
      languages = tmpLangs
      translations = data.translations
      languagesData = languages.map((lang) => allLanguages.find((l: any) => l.id === lang))
      formData = {}
      sent = false
    })
  }

  const saveForm = () => {
    formData = getFormData(form)
    fetchData('mod/i18nSave', {
      descriptions: formData.descriptions,
      languages,
      translations: formData.translations
    }).then((data) => {
      languages = []
      languagesData = []
      formData = {}
      jsons = {}
      sent = true
      notifier.success('Translations saved')
    })
  }

  $:if (Object.keys(translations).length) {
    const forma = form
    formData = getFormData(forma)
    Object.keys(formData.translations || {}).forEach((t) => {
      Object.keys(formData.translations[t] || {}).forEach((lang) => {
        if (!jsons[lang]) jsons[lang] = {}
        jsons[lang][t] = formData.translations[t][lang] || ''
      })
    })
  }

  const makeCsv = (json: Record<string, any>): string => {
    const csv: string[] = []
    Object.keys(json).forEach((key) => {
      if (translations[key]?.[1]) {
        csv.push(`"${key}","${json[key]}","${formData.descriptions[key] || ''}","${formData.translations[key][1] || ''}"`)
      } else {
        // eslint-disable-next-line no-console
        console.log('missing', key)
      }
    })
    return csv.join('\n')
  }

  const downloadFile = (l: string, csv = false) => {
    const link = document.createElement('a')
    const el = document.getElementById(`textarea-${l}${csv ? '-csv' : ''}`) as HTMLTextAreaElement
    const content = el?.value
    const file = new Blob([content], { type: csv ? 'text/csv' : 'application/json' })
    link.href = URL.createObjectURL(file)
    link.download = `${allLanguages.find((la: any) => toInt(la.id) === toInt(l)).iso}.${csv ? 'csv' : 'json'}`
    link.click()
    document.body.appendChild(link)
    URL.revokeObjectURL(link.href)
    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
  }
</script>
<p><a href='/admin/translations-import' use:link>Import</a></p>
<p>
  <label for='language'>Select language</label>
  {#if allLanguages.length > 0}
    <div class='svelecte-wrapper'>
      <Svelecte
        inputId='language'
        multiple
        options={allLanguages}
        placeholder='Select language'
        bind:value={languages}
      /></div>
    <button type='button' on:click={load}>Load</button>
  {/if}
</p>
<div class='sent-block' class:-hidden={sent}>
  {#if Object.keys(jsons).length}
    {#each Object.keys(jsons) as l}
      <div>
        <textarea id='textarea-{l}' value={JSON.stringify(unflattenObject(jsons[l]), null, 2)} style='display: none;' />

        <textarea id='textarea-{l}-csv' value={makeCsv(jsons[l])} style='display: none;' />

        <button type='button' on:click={() => downloadFile(l)}>{allLanguages.find(la => toInt(la.id) === toInt(l)).iso}.json </button>
        <br />
        <button type='button' on:click={() => downloadFile(l, true)}>{allLanguages.find(la => toInt(la.id) === toInt(l)).iso}.csv </button>
      </div>
    {/each}
  {/if}
  {#if Object.keys(translations).length}
    <form bind:this={form} action='' on:submit|preventDefault={saveForm}>
      <h2>Translations</h2>
      <input type='submit' value='Save' />
      <table>
        <tr>
          <td>Id</td>
          <th>English</th>
          <th>Other</th>
          <th>Description</th>
        </tr>
        {#each Object.keys(translations) as translationsString}
          {@const row = translations[translationsString]}
          <tr>
            <td title={translationsString}>{row[1]?.id || row[languages.find(l => l !== 1)]?.id}</td>
            <td class='english nopadding'>
              <input type='text' value={row[1]?.content || ''} name='translations[{translationsString}][1]' />
              {#if !row[1]?.content}<small>{row[1]?.name || ''}</small>{/if}
            </td>
            <td class='nopadding'>
              <table>
                {#each languages as rowLang}
                  {@const translation = row?.[rowLang] || {}}
                  {#if rowLang !== 1}
                    <tr class='row'>
                      <td class='td langname'>{languagesData.find(l => l.id === rowLang)?.iso || rowLang}</td>
                      <td class='td'>
                        <input tabindex={rowLang} type='text' value={translation.content || ''} name='translations[{translationsString}][{rowLang}]' />
                      </td>
                    </tr>
                  {/if}
                {/each}
              </table>
            </td>
            <td><input type='text' value={row[1]?.description || ''} name='descriptions[{translationsString}]' /></td>
          </tr>
        {/each}
      </table>
    </form>
  {/if}
</div>
<style lang='scss'>
  /* stylelint-disable rscss/class-format,rscss/no-descendant-combinator */
  .sent-block {
    &.-hidden {
      display: none;
    }
  }

  td,
  th,
  .td {
    padding: 0.4rem;
    border: solid var(--blue-3) 1px;
  }

  :global(.sv-item button) {
    min-width: 0;
  }

  table table {
    width: 100%;
    margin: 0;
  }

  .nopadding,
  .nopadding .td {
    padding: 0;
  }

  td input {
    width: 100%;
    margin: 0;
    padding: 0.2rem;
    background: var(--gray-1);
    border: none;
  }

  td.nopadding,
  td.english {
    min-width: 30%;
  }

  small {
    font-size: 1rem;
    color: var(--gray-3);
  }

  .langname {
    padding: 0 0.2rem;
  }

  table tr {
    &:nth-child(4n+1) {
      input { color: var(--blue-8); }
    }

    &:nth-child(4n+2) {
      input { color: var(--yellow-5); }
    }

    &:nth-child(4n+3) {
      input { color: var(--pink-4); }
    }
  }
</style>
