import { Dictionary } from '@/definitions/definitions'

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const formDataUnpack = (formData: FormData) => {
  const value = Object.fromEntries(formData.entries())
  const ret: Dictionary = {}
  for (const i in value) {
    if (i.includes('[')) {
      const tmp = i.replace(/]/g, '').split('[')
      const [t0, t1, t2] = tmp
      if (tmp.length === 2) {
        if (typeof ret[t0] === 'undefined') {
          ret[t0] = {}
        }
        ret[t0][t1] = value[i]
      } else if (tmp.length === 3) {
        if (typeof ret[t0] === 'undefined') {
          ret[t0] = {}
        }
        if (typeof ret[t0][t1] === 'undefined') {
          ret[t0][t1] = {}
        }
        ret[t0][t1][t2] = value[i]
      }
    } else {
      ret[i] = value[i]
    }
  }

  return ret
}

export const isNumber = (n: any) => !isNaN(n) && n !== ''

export const uniqBy = <T>(arr: T[], func: (item: T) => string) => {
  const seen: { [key: string]: boolean } = {}
  return arr.filter((item: T) => {
    const k = func(item)
    if (seen[k]) {
      seen[k] = true
      return false
    }
    seen[k] = true
    return true
  })
}

export const uniqObjects = (el: any) => uniqBy(el, JSON.stringify)

export const removeByItemValue = function (arr: any, key: string, val: any) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === val) {
      arr.splice(i, 1)
      return arr // delete this line if you want to remove all items (this remove just first occurence)
    }
  }
  return arr
}

/*
flattenObject({ a: { b: { c: 1 } }, d: 1 }); // { 'a.b.c': 1, d: 1 }
 */
export const flattenObject = (obj: Dictionary, prefix = '') =>
  Object.keys(obj).reduce((acc: Dictionary, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k))
    else acc[pre + k] = obj[k]
    return acc
  }, {})

/*
unflattenObject({ 'a.b.c': 1, d: 1 }); // { a: { b: { c: 1 } }, d: 1 }
unflattenObject({ 'a.b': 1, 'a.c': 2, d: 3 }); // { a: { b: 1, c: 2 }, d: 3 }
unflattenObject({ 'a.b.0': 8, d: 3 }); // { a: { b: [ 8 ] }, d: 3 }
 */
export const unflattenObject = (obj: Dictionary) =>
  Object.keys(obj).reduce((res, k) => {
    k.split('.').reduce(
      (acc: Dictionary, e, i, keys) =>
        acc[e] ||
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? obj[k]
            : {}
          : []),
      res
    )
    return res
  }, {})

/* Convert a 2D array into a CSV string */
export function arrayToCsv (data: string[][]) {
  return data.map((row: string[]) =>
    row
      .map(String)
      .map(v => v.replaceAll('"', '""'))
      .map(v => `"${v}"`)
      .join(',')).join('\r\n')
}

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob (content: string, filename: string, contentType = 'text/csv;charset=utf-8;') {
  // Create a blob
  const blob = new Blob([content], { type: contentType })
  const url = URL.createObjectURL(blob)

  // Create a link to download it
  const pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', filename)
  pom.click()
}

export const toInt = (n: string | number | undefined): number => {
  const num = typeof n === 'number' ? n : parseInt(n || '0', 10)
  return isNaN(num) ? 0 : num
}
